.close_btn{
    display: none;
}
@media only screen and (max-width: 374px) and (min-width: 320px) {
    .close_btn{
        display: block;
    }
}
@media only screen and (max-width: 426px) and (min-width: 375px) {
    .close_btn{
        display: block;
    }
}